import React from "react"
import { graphql, Link } from "gatsby"

import Img from "gatsby-image"

const StripListItem = ({ node }) => {

    return (
        <Link to={node.frontmatter.path} className="grid gap-x-4 hover:bg-gray-100 md:grid-cols-40rem40rem items-center md:justify-center text-indigo-600 hover:text-pink-600">
            <div className="tile_featuredImage justify-self-stretch">
              {node.frontmatter.featuredImage && <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />}</div>
            <div className="justify-self-center md:justify-self-end text-center md:text-right md:row-start-1 md:col-start-1">
              <h3 className="text-3xl">{node.frontmatter.title}</h3>
              <time className="text-sm">{node.frontmatter.date}</time>
            </div>
        </Link>
    );

}

export default StripListItem;