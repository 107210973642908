import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import StripListItem from './StripListItem';

const  StripList = ({ posts }) => {
    return (
      <div className="grid items-center grid-cols-1 mx-auto gap-y-4 justify-items-stretch">
        {posts.edges.map(({ node }) => {
          return (
            <StripListItem node={node} />
          );
        })}
      </div>
    );
  }

  export default StripList;