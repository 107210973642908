import React from "react"
import { graphql, Link } from "gatsby"
import { get } from "lodash";
import Layout from "../components/layout"
import StripList from '../components/_shared/stripList/StripList';
import PageHeader from '../components/_shared/PageHeader';

const ListPosts = ({ posts }) => {
  return posts.edges.map(({ node }) => {
    return (
      <article>
        <Link to={node.frontmatter.path}>
          {node.frontmatter.title}
        </Link>
      </article>
    );
  })
}

const DisplayPosts = ({ posts }) => {
  return (
    <div className="grid justify-center gap-8">
      {posts.edges.map(({ node }) => {
        return (
          <article className="container max-w-screen-sm">
            <header className="mb-2">
              <h2 className="mb-2">
                <Link to={node.frontmatter.path}>
                  {node.frontmatter.title}
                </Link>
              </h2>
              <h3><time>{node.frontmatter.date}</time></h3>
            </header>
            <div dangerouslySetInnerHTML={{ __html: node.html }} />
          </article>
        );
      })}
    </div>
  );
}

const templateLookUp = {
  list: ListPosts,
  tiles: StripList,
  display: DisplayPosts
}

export default function Template({
  data
}) {
  const { markdownRemark, miniProjectUpdates } = data;
  const { frontmatter, html } = markdownRemark;
  const { optionListing } = frontmatter;
  const Posts = get(templateLookUp, optionListing, ListPosts);
  return (
    <Layout>
      <article className="mainArticle">
        <PageHeader preTitle="Mini Project" title={frontmatter.title} />
        <div className="articleBody"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
      <Posts posts={miniProjectUpdates} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!, $miniProject: String!) {
    markdownRemark(frontmatter: {path: {eq: $path } }) {
      html
      frontmatter {
        date(formatString: "Do MMMM, YYYY")
        path
        title
        optionListing
      }
    },
    miniProjectUpdates: allMarkdownRemark(filter: {frontmatter: {miniProject: {eq: $miniProject}, type: {eq: "mini-project-update"}}}, sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
        edges {
          node {
            html
            frontmatter {
              date(formatString: "Do MMMM, YYYY")
              title
              path
              type
              miniProject
              featuredImage {
                childImageSharp {
                fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`